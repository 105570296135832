import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderOne, ContactTwo, FooterOne } from "../containers";

const Contact = () => (
  <Fragment>
    <Seo title="Contact Us" />
    <HeaderOne />
    <Breadcrumb
      pageName="Contact Us"
      bgImage="https://ik.imagekit.io/nce63ejyh/Alkas/audio-headset-used-by-call-center-agents-help-clients-telecommunication-with-technology-empty-customer-service-workstation-with-headphones-computers-modern-gadgets-min.jpg?updatedAt=1707733738538"
    />
    <ContactTwo className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default Contact;
