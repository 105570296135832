import React from "react";
import { SecTitle, SecSubTitle, InfoMedia, FormOne } from "../../components";

const ContactOne = () => (
  <div
    className="background-image"
    style={{
      backgroundImage:
        "url(https://ik.imagekit.io/nce63ejyh/Alkas/appoin-bg-1-1.jpg?updatedAt=1705220710070)",
    }}
  >
    <div className="container z-index-common">
      <div className="row gx-60">
        <div className="col-xl-6 align-self-center space">
          <SecSubTitle className="text-white">Make An Appointment</SecSubTitle>
          <SecTitle className="text-capitalize h1 text-white">
            We Provide Best Advice For Your Future
          </SecTitle>
          <hr className="hr-style1" />
          <p className="mb-4 mt-1 pb-3 text-white">
            Seize the opportunity to optimize your financial journey schedule an
            appointment today and let us tailor our expertise to meet your
            specific needs
          </p>
          <div className="row gy-30">
            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-envelope"
                title="24/7 CALL AVAILABLE"
                info={<a href="tel:+9663565446789">+966356 5446</a>}
              />
            </div>
            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-envelope"
                title="Write a Quick mail"
                info={<a href="mailto:support@alkas.com">support@alkas.com</a>}
              />
            </div>
            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-envelope"
                title="VISITE Saudi OFFICE"
                info="3401 Riyadh , Saudi Arabia"
              />
            </div>
            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-envelope"
                title="VISITE Saudi OFFICE"
                info="3401 Riyadh , Saudi Arabia "
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 form-wrap1">
          <FormOne title="Make An Appointment" btnText="Make An Appointment" />
        </div>
      </div>
    </div>
  </div>
);

export default ContactOne;
