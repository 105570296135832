import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

// FAQ Data
import faqData from "../../data/faq.json";

const ServiceSingle = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-8">
          <div className="mb-3 pb-3">
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/workplace-results-professional-report-accounting-during-min.jpg?updatedAt=1707731359093"
              alt="Project"
            />
          </div>
          <h2 className="h4">Accounting & Audits</h2>
          <p>
            Discover a seamless blend of precision and reliability as our
            seasoned professionals navigate the complexities of modern
            accounting. From streamlined bookkeeping solutions to in depth
            financial audits, we are dedicated to fortifying your financial
            foundations. Our commitment to transparency and adherence to
            industry standards sets us apart. At Alkas, we go beyond the
            numbers, offering strategic insights that empower your decision
            making process. Our audit procedures are thorough, ensuring the
            highest level of accuracy and compliance.
          </p>
          <div className="row gx-0 mb-4 pb-2 pt-3">
            <div className="col-xl-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/businesspeople-working-finance-accounting-analyze-financi-min.jpg?updatedAt=1707731561795"
                alt="project"
                className="w-100"
              />
            </div>
            <div className="col-xl-6">
              <div className="service-list-box">
                <h3 className="h5 title">Service Features</h3>
                <List className="list-style3">
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Audit and Assurance
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Monthly PnL
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Trial Balance & Financial Statement
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Zakat and Audit
                  </List.Item>
                </List>
              </div>
            </div>
          </div>
          <h3 className="h5">Our Accounting Services</h3>
          <p>
            Explore a suite of accounting solutions tailored to meet your unique
            needs. From meticulous bookkeeping and financial statement
            preparation to budgeting and forecasting, our team of experts
            ensures your financial data is not just recorded but transformed
            into actionable insights that drive business growth.
          </p>
          <p>
            Trust is the cornerstone of successful businesses. Our audit
            services, conducted by independent experts, provide a thorough
            examination of your financial statements and internal controls. Gain
            confidence in the accuracy and reliability of your financial
            information, and meet regulatory requirements with ease.
          </p>
          <div className="row pt-3 mb-30 pb-10 gy-30">
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/executives-reviewing-last-report-min.jpg?updatedAt=1707732922205"
                alt="project"
              />
            </div>
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/elegant-executives-reviewing-report-min.jpg?updatedAt=1707732960203"
                alt="project"
              />
            </div>
          </div>
          <Accordion
            defaultActiveKey={faqData[0].questionId}
            className="accordion-style1 layout2"
          >
            {faqData.map((item) => (
              <Accordion.Item key={item.questionId} eventKey={item.questionId}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>
                  <p>{item.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="col-lg-4">
          <SidebarTwo />
        </div>
      </div>
    </div>
  </div>
);

export default ServiceSingle;
