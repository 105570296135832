import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ image, className }) => (
  <Link className={`vs-logo ${className || ""}`} to="/">
    <img src={image} alt="Logo" style={{ width: "80px" }} />
  </Link>
);

export default Logo;
