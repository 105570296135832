import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderOne, FooterOne } from "../containers";
import ServiceConstruction from "../containers/Services/ServiceConstruction";

const ConstructionSection = () => (
  <Fragment>
    <Seo
      title="Construction & Industrial Support
"
    />
    <HeaderOne />
    <Breadcrumb
      pageName="Construction & Industrial Support
      "
      bgImage="https://ik.imagekit.io/nce63ejyh/Alkas/crane-holding-iron-construction-where-sitting-builder-with-bare-torso-eating-drinking-milk-extreme-building-high-cityscape-background-min.jpg?updatedAt=1707763003770"
    />
    <ServiceConstruction className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default ConstructionSection;
