import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderOne, ServiceSingle, FooterOne } from "../containers";
import ServiceManPower from "../containers/Services/ServiceManPower";

const ManPowerSection = () => (
  <Fragment>
    <Seo title="Manpower Supply" />
    <HeaderOne />
    <Breadcrumb
      pageName="Manpower Supply"
      bgImage="https://ik.imagekit.io/nce63ejyh/Alkas/company-managers-visiting-their-employees-factory-happy-african-american-businesswoman-is-shaking-hands-with-one-worker-min-min.jpg?updatedAt=1707740335145"
    />
    <ServiceManPower className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default ManPowerSection;
