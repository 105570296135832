import React from "react";
import Slider from "react-slick";
import { TitleWrap, SecSubTitle, SecTitle, TeamBoxTwo } from "../../components";
import team1 from "../../assets/images/team/t-2-1.jpg";
import team2 from "../../assets/images/team/t-2-2.jpg";
import team3 from "../../assets/images/team/t-2-3.jpg";
import team4 from "../../assets/images/team/t-2-4.jpg";

const TeamTwo = ({ ...restProps }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div {...restProps}>
      <div className="container">
        <TitleWrap className="text-center">
          <SecSubTitle>GREAT TEAM MEMBERS</SecSubTitle>
          <SecTitle className="h1 text-capitalize">
            We Have Expert Team
          </SecTitle>
        </TitleWrap>
        <Slider {...settings}>
          <TeamBoxTwo
            image={team1}
            name="Grayson Gabriel"
            desgination="Cheif Expert"
            // path="/team-details"
          />
          <TeamBoxTwo
            image={team2}
            name="Samuel Thomas"
            desgination="Cheif Expert"
            // path="/team-details"
          />
          <TeamBoxTwo
            image={team3}
            name="William Lucas"
            desgination="Accountant"
            // path="/team-details"
          />
          <TeamBoxTwo
            image={team4}
            name="Daniel Matthew"
            desgination="Chartered Accountant"
            // path="/team-details"
          />
        </Slider>
      </div>
    </div>
  );
};

export default TeamTwo;
