import React from "react";
import { InfoMedia } from "../../components";

import counterbg1 from "../../assets/images/counterbg/counter-bg-2-1.jpg";

const CounterTwo = ({ className }) => (
  <div
    className={`background-image ${className || ""}`}
    style={{
      backgroundImage:
        "url(https://ik.imagekit.io/nce63ejyh/Alkas/counter-bg-2-1.jpg?updatedAt=1705218493295)",
    }}
  >
    <div className="container">
      <div className="row justify-content-between gy-4">
        <div className="col-6 col-lg-auto">
          <InfoMedia
            className="counter-media"
            image="images/icon/count-1-1.png"
            title="850+"
            info="Successful Projects"
          />
        </div>
        <div className="col-6 col-lg-auto">
          <InfoMedia
            className="counter-media"
            image="images/icon/count-1-2.png"
            title="5+"
            info="years Experience"
          />
        </div>
        <div className="col-6 col-lg-auto">
          <InfoMedia
            className="counter-media"
            image="images/icon/count-1-3.png"
            title="567"
            info="Skilled Experts"
          />
        </div>
        <div className="col-6 col-lg-auto">
          <InfoMedia
            className="counter-media"
            image="images/icon/count-1-4.png"
            title="28k"
            info="Happy Clients"
          />
        </div>
      </div>
    </div>
  </div>
);

export default CounterTwo;
