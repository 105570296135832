import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

// FAQ Data
import construction from "../../data/construction.json";

const ServiceConstruction = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-8">
          <div className="mb-3 pb-3">
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/construction-silhouette-min.jpg?updatedAt=1707764592241"
              alt="Project"
            />
          </div>
          <h2 className="h4">Construction & Industrial Support</h2>
          <p>
            Dedicated partner in Construction and Industrial Support services.
            With a commitment to excellence and safety, we specialize in
            providing comprehensive solutions tailored to meet the unique needs
            of the construction and industrial sectors. From skilled labor and
            project management to equipment rental and logistics support, Alkas
            is your one-stop destination for a seamless and efficient
            construction experience.
          </p>
          <div className="row gx-0 mb-4 pb-2 pt-3">
            <div className="col-xl-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/pointing-sketch-min.jpg?updatedAt=1707764592306"
                alt="project"
                className="w-100"
              />
            </div>
            <div className="col-xl-6">
              <div className="service-list-box">
                <h3 className="h5 title">Service Features</h3>
                <List className="list-style3">
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Skilled Workforce
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Project Management
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Equipment Rental
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Safety Compliance
                  </List.Item>
                </List>
              </div>
            </div>
          </div>
          <h3 className="h5">Our Construction & Industrial Services</h3>
          <p>
            Our experienced team brings industry-specific expertise, ensuring
            that your projects are executed with precision and adherence to the
            highest safety standards. Whether you are embarking on a large-scale
            construction project or require reliable industrial support
            services, Alkas is poised to exceed your expectations.
          </p>
          <p>
            At Alkas, we understand the dynamic challenges of the construction
            and industrial landscape. Our solutions are designed to optimize
            efficiency, reduce downtime, and enhance overall project success.
            Partner with us for a reliable, experienced, and safety-focused
            approach to Construction and Industrial Support. Your vision is our
            priority, and we are dedicated to ensuring that your projects are
            not just built but built to last.
          </p>
          <div className="row pt-3 mb-30 pb-10 gy-30">
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/happy-workers-construction-site-young-civil-engineer-manager-architects-handshaking-construction-site-looking-construction-phase-cooperation-teamwork-concept-min.jpg?updatedAt=1707764592263"
                alt="project"
              />
            </div>
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/close-up-men-work-with-laptop-min.jpg?updatedAt=1707764592254"
                alt="project"
              />
            </div>
          </div>
          <Accordion
            defaultActiveKey={construction[0].questionId}
            className="accordion-style1 layout2"
          >
            {construction.map((item) => (
              <Accordion.Item key={item.questionId} eventKey={item.questionId}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>
                  <p>{item.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="col-lg-4">
          <SidebarTwo />
        </div>
      </div>
    </div>
  </div>
);

export default ServiceConstruction;
