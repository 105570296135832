import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderOne, ServiceSingle, FooterOne } from "../containers";

const AccountingSection = () => (
  <Fragment>
    <Seo title="Accounting & Audits" />
    <HeaderOne />
    <Breadcrumb
      pageName="Accounting & Audits"
      bgImage="https://ik.imagekit.io/nce63ejyh/Alkas/revenue-operations-concept-min.jpg?updatedAt=1707723305480"
    />
    <ServiceSingle className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default AccountingSection;
