import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

// FAQ Data
import manpower from "../../data/manpower.json";

const ServiceManPower = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-8">
          <div className="mb-3 pb-3">
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/people-safety-equipment-work-min.jpg?updatedAt=1707762122148"
              alt="Project"
            />
          </div>
          <h2 className="h4">Manpower Supply</h2>
          <p>
            We specialize in providing skilled and reliable manpower to meet
            your business needs, ensuring a seamless integration of talent that
            aligns with your objectives. With a commitment to quality,
            efficiency, and customer satisfaction, Whether you require temporary
            staffing or long-term personnel, we're dedicated to delivering the
            right manpower to drive your success. Partner with us and experience
            the difference that strategic manpower supply can make for your
            business.
          </p>
          <div className="row gx-0 mb-4 pb-2 pt-3">
            <div className="col-xl-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/people-safety-equipment-working-min.jpg?updatedAt=1707762355660"
                alt="project"
                className="w-100"
              />
            </div>
            <div className="col-xl-6">
              <div className="service-list-box">
                <h3 className="h5 title">Service Features</h3>
                <List className="list-style3">
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Comprehensive Manpower Solutions
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Flexible Staffing
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Industry-specific Expertise
                  </List.Item>
                  <List.Item>
                    <i className="fal fa-check-circle" />
                    Streamlined Workforce
                  </List.Item>
                </List>
              </div>
            </div>
          </div>
          <h3 className="h5">Our Manpower Services</h3>
          <p>
            Our flexible staffing solutions cater to both temporary and
            permanent requirements, ensuring that you have the right talent when
            and where you need it. With a deep understanding of various
            industries, our expertise enables us to provide personnel that
            aligns seamlessly with your specific sector.
          </p>
          <p>
            As your go-to resource for streamlined and tailored workforce
            solutions, we take pride in being a strategic asset to your
            organization. Experience the ease of optimized staffing with Alkas,
            where we seamlessly integrate skilled professionals to elevate the
            success of your business.
          </p>
          <div className="row pt-3 mb-30 pb-10 gy-30">
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/colleagues-safety-equipment-work-min.jpg?updatedAt=1707762423141"
                alt="project"
              />
            </div>
            <div className="col-md-6">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/man-safety-equipment-his-workplace-min.jpg?updatedAt=1707762500871"
                alt="project"
              />
            </div>
          </div>
          <Accordion
            defaultActiveKey={manpower[0].questionId}
            className="accordion-style1 layout2"
          >
            {manpower.map((item) => (
              <Accordion.Item key={item.questionId} eventKey={item.questionId}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>
                  <p>{item.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="col-lg-4">
          <SidebarTwo />
        </div>
      </div>
    </div>
  </div>
);

export default ServiceManPower;
