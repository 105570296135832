import React from "react";
import Slider from "react-slick";

const BrandTwo = ({ className }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div
      className={`background-image ${className || ""}`}
      style={{ backgroundImage: "url(images/bg/brand-bg-2-1.jpg)" }}
    >
      <div className="container">
        <Slider className="row text-center" {...settings}>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/photo1707766103.jpeg?updatedAt=1707766417528"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/photo1707766085.jpeg?updatedAt=1707766417578"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/zoho.jfif?updatedAt=1707766681466"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/download.png?updatedAt=1707766417745"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/odoo.png?updatedAt=1707766417759"
              alt="brands"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/nce63ejyh/Alkas/tally.png?updatedAt=1707766681493"
              alt="brands"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default BrandTwo;
